import React, { useContext } from "react";
import "./App.scss";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors } from "components/variables";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "context-api/auth-provider";
import { LoginComponent } from "pages/login";

const AppWrapper = styled.div`
  background-color: ${colors.backgroundColor};
  width: 100%;
  height: 100%;
  padding: 4%;
`;

function App() {
  const { token } = useContext(AuthContext);

  return <AppWrapper>{token ? <Outlet /> : <LoginComponent />}</AppWrapper>;
}

export default App;
