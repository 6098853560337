import { getCampaign } from "api/campaign";
import { getTicketsForOrder } from "api/order";
import {
  StyledButton,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { ItemCardLarge } from "components/item-card-large";
import { colors } from "components/variables";
import { AuthContext } from "context-api/auth-provider";
import { DateTime } from "luxon";
import { Campaign } from "models/Campaign";
import { Ticket } from "models/Ticket";
import { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import styled from "styled-components";

const StyledTicketsComponent = styled.div`
  .back-wrapper {
    width: 240px;
    margin-right: auto;

    button {
      justify-content: left;
    }
  }
`;

const StyledTicketsTableComponent = styled.table`
  width: 100%;
`;

export const Tickets = () => {
  const campaignId = useLoaderData() as string;
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    getCampaign(campaignId, token).then((res) => {
      setCampaign(res.data);
    });
  }, []);

  useEffect(() => {
    getTicketsForOrder(campaignId, token).then((tickets) => {
      setTickets(tickets.data);
    });
  }, []);

  const item = {
    id: Number(campaignId),
    image: `${campaign?.campaignImage}`,
    status: campaign?.status === 1 ? "active" : "inactive",
    subtitle: campaign?.closingDate
      ? DateTime.fromISO(campaign?.closingDate).toFormat("dd/LL/yyyy")
      : "",
    title: campaign?.campaignTitle ?? "",
  };

  const stat = [
    { title: "Total items", value: campaign?.totalItemNumber || 0 },
    { title: "Items sold", value: campaign?.soldItemNumber || 0 },
    { title: "Number of offers", value: campaign?.numberOfOffers },
    {
      title: "Published date",
      value: campaign?.publishDate
        ? DateTime.fromISO(campaign?.publishDate).toFormat("dd/LL/yyyy")
        : "",
    },
    {
      title: "Closing date",
      value: campaign?.closingDate
        ? DateTime.fromISO(campaign.closingDate).toFormat("dd/LL/yyyy")
        : "",
    },
  ];

  return (
    <StyledTicketsComponent>
      <div className="back-wrapper mb-4">
        <StyledLink to="/tickets">
          <StyledButton>⬅️ Back</StyledButton>
        </StyledLink>
      </div>

      <ItemCardLarge item={{ ...item, stats: stat }} isActive={true} />

      <StyledTicketsTableComponent className="history-table mt-3">
        <thead>
          <tr>
            <th>User</th>
            {/* <th>Shipping address</th> */}
            <th>NIC</th>
            <th>Ticket number</th>
            <th>Draw</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket, i) => (
            <tr key={i}>
              <td>{ticket.userName}</td>
              {/* <td>WTC West Tower, Level 35, 00100</td> */}
              <td>{ticket.nic}</td>
              <td>{ticket.ticketNumber}</td>
              <td>{DateTime.fromISO(ticket.draw).toFormat("dd LLLL yyyy")}</td>
              <td>{ticket.status === "0" ? "Allocated" : "Drawn"}</td>
            </tr>
          ))}
        </tbody>
      </StyledTicketsTableComponent>
    </StyledTicketsComponent>
  );
};
