import { createContext, useState } from "react";

interface IAuthContext {
  token: string;
  setToken: (token: string) => void;
}

const defaultValue: IAuthContext = {
  token: "",
  setToken: (token: string) => {},
};

export const AuthContext = createContext(defaultValue);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState<string>("");

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};
