import {
  addCampaign,
  deleteCampaign,
  getCampaigns,
  searchCampaigns,
  updateCampaign,
} from "api/campaign";
import { getOffers } from "api/offer";
import { getProducts } from "api/product";
import placeholder from "assets/images/placeholder.webp";
import {
  AppSpinner,
  StyledButton,
  StyledFormControl,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { ItemSidebarComponent } from "components/items-sidebar";
import { DefaultAddManageWrapper } from "components/layout";
import { colors } from "components/variables";
import { AuthContext } from "context-api/auth-provider";
import { DateTime } from "luxon";
import { Campaign } from "models/Campaign";
import { Item } from "models/Item";
import { Offer } from "models/Offer";
import { Product } from "models/Product";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledLayout = styled(DefaultAddManageWrapper)<{ isManage?: boolean }>`
  .page-content {
    gap: 3rem;
    overflow-y: auto;
    /* flex-direction: ${(props) => (props.isManage ? "column" : "row")}; */
  }
`;

export const AddManageCampaignComponent = (props: { isManage?: boolean }) => {
  const { control, register, handleSubmit, watch, setValue, reset } =
    useForm<Campaign>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "subOffersFields",
  });
  const numOffers = watch("numberOfOffers");
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState<File>();
  const fileUpload = useRef<HTMLInputElement>(null);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getSubOfferIds = (subOffers: { offerId: number | null }[]) => {
    return (subOffers || [])
      .filter((suboffer) => suboffer !== null)
      .map((subOffer) => subOffer.offerId)
      .join(",");
  };

  const handleAddCampaign = async (form: Campaign) => {
    setIsLoading(true);

    //pass as string since form data doesn't support arrays
    const subOfferIds = getSubOfferIds(form.subOffersFields!);

    const publishedDate = new Date(form.publishDate);
    const closingDate = new Date(form.closingDate);
    const drawDate = new Date(form.drawDate);

    const formData = new FormData();
    formData.append("campaignTitle", form.campaignTitle);
    formData.append("campaignTitleSi", form.campaignTitleSi);
    formData.append("campaignTitleTa", form.campaignTitleTa);

    formData.append("campaignDescription", form.campaignDescription);
    formData.append("campaignDescriptionSi", form.campaignDescriptionSi);
    formData.append("campaignDescriptionTa", form.campaignDescriptionTa);

    formData.append("publishDate", publishedDate.toUTCString());
    formData.append("closingDate", closingDate.toUTCString());
    formData.append("drawDate", drawDate.toUTCString());
    formData.append("image", file as Blob);
    formData.append("productId", `${form.productId}`);
    formData.append("offerId", `${form.offerId}`);
    formData.append("numberOfOffers", `${form.numberOfOffers}`);
    formData.append("numberOfChances", `${form.numberOfChances}`);
    formData.append("totalItemNumber", `${form.totalItemNumber}`);
    formData.append("subOffers", subOfferIds);
    formData.append("type", form.type);
    formData.append("status", `${form.status || 0}`);

    try {
      await addCampaign(formData, token);
      setIsLoading(false);
      toast.success("Campaign created");
      navigate("/");
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const onUpdateCampaign = async (form: Campaign) => {
    setIsLoading(true);

    const subOfferIds = getSubOfferIds(form.subOffersFields!);

    const payload: Campaign = {
      campaignTitle: form.campaignTitle,
      campaignTitleSi: form.campaignTitleSi,
      campaignTitleTa: form.campaignTitleTa,
      campaignDescription: form.campaignDescription,
      campaignDescriptionSi: form.campaignDescriptionSi,
      campaignDescriptionTa: form.campaignDescriptionTa,
      publishDate: form.publishDate,
      closingDate: form.closingDate,
      productId: form.productId,
      drawDate: form.drawDate,
      offerId: form.offerId,
      numberOfOffers: form.numberOfOffers,
      numberOfChances: form.numberOfChances,
      totalItemNumber: form.totalItemNumber,
      subOffers: subOfferIds,
      type: form.type,
      status: form.status,
    };

    try {
      await updateCampaign(selectedItem?.id! as number, payload, token);
      setIsLoading(false);
      toast.success(`Campaign updated`);
      const updatedCampaignIndex = campaigns.findIndex(
        (campaign) => campaign.id === selectedItem?.id
      );
      const campaignCopy = [...campaigns];
      const oldCampaign = campaignCopy[updatedCampaignIndex];
      const newCampaign: Campaign = {
        ...oldCampaign,
        campaignTitle: payload.campaignTitle,
        campaignTitleSi: payload.campaignTitleSi,
        campaignTitleTa: payload.campaignTitleTa,
        campaignDescription: payload.campaignDescription,
        campaignDescriptionSi: payload.campaignDescriptionSi,
        campaignDescriptionTa: payload.campaignDescriptionTa,
        publishDate: payload.publishDate,
        closingDate: payload.closingDate,
        productId: payload.productId,
        offerId: payload.offerId,
        numberOfOffers: payload.numberOfOffers,
        numberOfChances: payload.numberOfChances,
        totalItemNumber: payload.totalItemNumber,
        subOffers: subOfferIds,
        type: payload.type,
        status: payload.status,
        drawDate: payload.drawDate,
      };
      campaignCopy[updatedCampaignIndex] = newCampaign;
      setCampaigns(campaignCopy);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const onDeleteCampaign = async () => {
    setIsLoading(true);

    try {
      await deleteCampaign(selectedItem?.id as number, token);
      setIsLoading(false);
      toast.success("Campaign deleted");
      setCampaigns(campaigns.filter((cam) => cam.id !== selectedItem?.id));
      reset();
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;
    if (files) {
      setFile(files[0]);
      const reader = new FileReader();
      const url = reader.readAsDataURL(files[0]);
      reader.onloadend = (e: any) => {
        setFileUrl(reader.result as string);
      };
    }
  };

  useEffect(() => {
    const newValue = numOffers;
    const oldValue = fields.length;

    if (newValue > oldValue) {
      for (let i = oldValue; i < newValue; i++) {
        append({ offerId: null });
      }
    } else {
      for (let i = oldValue; i > newValue; i--) {
        remove(i - 1);
      }
    }
  }, [numOffers]);

  const [activeProducts, setActiveProducts] = useState<Product[]>([]);
  useEffect(() => {
    setIsLoading(true);

    getProducts(token)
      .then((res) => {
        setActiveProducts(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, []);

  const [activeOffers, setActiveOffers] = useState<Offer[]>([]);
  useEffect(() => {
    setIsLoading(true);

    getOffers(token)
      .then((res) => {
        setActiveOffers(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, []);

  const onSelectionChanged = (item: Item) => {
    const selected = campaigns.find((c) => c.id === item.id)!;
    setSelectedItem(item);

    const publishDate =
      selected.publishDate &&
      DateTime.fromISO(selected.publishDate).toFormat("yyyy-MM-dd");
    const closingDate =
      selected.closingDate &&
      DateTime.fromISO(selected.closingDate).toFormat("yyyy-MM-dd");
    const drawDate =
      selected.drawDate &&
      DateTime.fromISO(selected.drawDate).toFormat("yyyy-MM-dd");

    const subOffers = selected.subOffers
      ?.split(",")
      .map((s) => ({ offerId: Number(s) }));

    setValue("campaignTitle", selected.campaignTitle);
    setValue("campaignTitleSi", selected.campaignTitleSi);
    setValue("campaignTitleTa", selected.campaignTitleTa);
    setValue("campaignDescription", selected.campaignDescription);
    setValue("campaignDescriptionSi", selected.campaignDescriptionSi);
    setValue("campaignDescriptionTa", selected.campaignDescriptionTa);
    setValue("publishDate", publishDate);
    setValue("closingDate", closingDate);
    setValue("productId", selected.productId);
    setValue("offerId", selected.offerId);
    setValue("numberOfOffers", selected.numberOfOffers);
    setValue("numberOfChances", selected.numberOfChances);
    setValue("totalItemNumber", selected.totalItemNumber);
    setValue("type", selected.type);
    setValue("status", selected.status);
    setValue("subOffersFields", subOffers);
    setValue("drawDate", drawDate);
  };

  const onSearchTextChanged = async (text: string) => {
    setIsLoading(true);
    setSearchText(text);
    setSelectedItem(null);

    try {
      const response = await searchCampaigns(text, token);
      setIsLoading(false);
      if (response.data.length === 0) {
        toast.error(`No products found for '${text}'`);
      }
      setCampaigns(response.data);
    } catch (err: any) {}
  };

  useEffect(() => {
    setIsLoading(true);

    getCampaigns(token)
      .then((res) => {
        setIsLoading(false);
        setCampaigns(res.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <StyledLayout isManage={props.isManage}>
      <AppSpinner show={isLoading}></AppSpinner>
      <div className="back-wrapper mb-4">
        <StyledLink to="/">
          <StyledButton>⬅️ Back</StyledButton>
        </StyledLink>
      </div>

      <h2 className="page-title mb-4">
        {props.isManage ? "Manage Campaigns" : "Add Campaign"}
      </h2>

      <div className="page-content">
        {props.isManage ? (
          <div className="sidebar">
            <ItemSidebarComponent
              items={campaigns.map((campaign) => ({
                id: campaign.id as number,
                image: campaign.campaignImage as string,
                title: campaign.campaignTitle,
                status:
                  campaign.status === 0
                    ? "Draft"
                    : campaign.status === 1
                    ? "Published"
                    : "Closed",
                subtitle:
                  `${campaign.closingDate}` || `${campaign.totalItemNumber}`,
              }))}
              selectionChange={onSelectionChanged}
              activeItemId={selectedItem?.id as number}
              search={onSearchTextChanged}
            ></ItemSidebarComponent>
          </div>
        ) : null}

        <div className="product-info">
          <Form
            onSubmit={handleSubmit(
              props.isManage ? onUpdateCampaign : handleAddCampaign
            )}
          >
            <fieldset disabled={props.isManage && !selectedItem ? true : false}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Title - English</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("campaignTitle")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="nameSi">
                <Form.Label>Title - Sinhala</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("campaignTitleSi")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="nameTa">
                <Form.Label>Title - Tamil</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("campaignTitleTa")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="desc">
                <Form.Label>Description - English</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("campaignDescription")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="descSi">
                <Form.Label>Description - Sinhala</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("campaignDescriptionSi")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="descTa">
                <Form.Label>Description - Tamil</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("campaignDescriptionTa")}
                />
              </Form.Group>

              <div className="date-section">
                <Form.Group className="mb-3" controlId="pubDate">
                  <Form.Label>Published date</Form.Label>
                  <StyledFormControl
                    type="date"
                    placeholder=""
                    {...register("publishDate")}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="closeDate">
                  <Form.Label>Closing date</Form.Label>
                  <StyledFormControl
                    type="date"
                    placeholder=""
                    {...register("closingDate")}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="closeDate">
                  <Form.Label>Draw date</Form.Label>
                  <StyledFormControl
                    type="date"
                    placeholder=""
                    {...register("drawDate")}
                  />
                </Form.Group>
              </div>

              <Form.Group className="mb-3" controlId="product">
                <Form.Label>Product</Form.Label>
                <Form.Select {...register("productId")}>
                  <option value=""></option>
                  {activeProducts.map((product) => (
                    <option value={product.id} key={product.id}>
                      {" "}
                      {product.productName}{" "}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="offer">
                <Form.Label>Offer</Form.Label>
                <Form.Select {...register("offerId")}>
                  <option value=""></option>
                  {activeOffers.map((offer) => (
                    <option value={offer.id} key={offer.id}>
                      {" "}
                      {offer.offerTitle}{" "}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="numOffers">
                <Form.Label>Number of offers</Form.Label>
                <Form.Select {...register("numberOfOffers")}>
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="campaignType">
                <Form.Label>Campaign type</Form.Label>
                <Form.Select {...register("type")}>
                  <option value="1">Item limit</option>
                  <option value="2">Time limit</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="chances">
                <Form.Label>Number of chances</Form.Label>
                <StyledFormControl
                  type="number"
                  placeholder=""
                  step="1"
                  {...register("numberOfChances", {
                    valueAsNumber: true,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="items">
                <Form.Label>Total item number</Form.Label>
                <StyledFormControl
                  type="number"
                  placeholder=""
                  step="1"
                  {...register("totalItemNumber", {
                    valueAsNumber: true,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="status">
                <Form.Label>Status</Form.Label>
                <Form.Select {...register("status")}>
                  <option value=""></option>
                  <option value="0">Draft</option>
                  <option value="1">Published</option>
                  <option value="2">Closed</option>
                </Form.Select>
              </Form.Group>

              {!props.isManage ? (
                <div className="add-container">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                    type="submit"
                  >
                    Add
                  </StyledButton>
                </div>
              ) : null}

              {props.isManage ? (
                <div className="sub-offer">
                  {fields.map((field, i) => (
                    <Form.Group
                      className="mb-3"
                      controlId="campaignType"
                      key={field.id}
                    >
                      <Form.Label>Sub offer {i + 1}</Form.Label>
                      <Form.Select
                        {...register(`subOffersFields.${i}.offerId`)}
                      >
                        <option value=""></option>
                        {activeOffers.map((offer) => (
                          <option value={offer.id} key={offer.id}>
                            {" "}
                            {offer.offerTitle}{" "}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  ))}
                </div>
              ) : null}

              {props.isManage ? (
                <div className="d-flex actions">
                  <div className="add-container">
                    <StyledButton
                      backgroundColor={colors.colorMain}
                      color={colors.white}
                      type="submit"
                    >
                      Update
                    </StyledButton>
                  </div>
                  <div className="add-container">
                    <StyledButton
                      backgroundColor={colors.accent}
                      color={colors.black}
                      type="button"
                      onClick={onDeleteCampaign}
                    >
                      Delete
                    </StyledButton>
                  </div>
                </div>
              ) : null}
            </fieldset>
          </Form>
        </div>

        {!props.isManage ? (
          <div className="product-image">
            <div className="upload-container mb-5">
              <div className="image-container mb-3">
                <img className="preview" src={fileUrl || placeholder} />
              </div>
              <div className="upload-image-btn">
                <StyledButton
                  color={colors.white}
                  backgroundColor={colors.colorMain}
                  onClick={() => {
                    fileUpload.current?.click();
                  }}
                >
                  Upload
                </StyledButton>
              </div>

              <input
                className="d-none"
                type="file"
                onChange={(e) => handleFileChange(e)}
                ref={fileUpload}
              />
            </div>

            <div className="sub-offer">
              {fields.map((field, i) => (
                <Form.Group
                  className="mb-3"
                  controlId="campaignType"
                  key={field.id}
                >
                  <Form.Label>Sub offer {i + 1}</Form.Label>
                  <Form.Select {...register(`subOffersFields.${i}.offerId`)}>
                    <option value=""></option>
                    {activeOffers.map((offer) => (
                      <option value={offer.id} key={offer.id}>
                        {" "}
                        {offer.offerTitle}{" "}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </StyledLayout>
  );
};
