import axios from "axios";
import {
  AppSpinner,
  StyledButton,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { ItemList } from "components/item-list";
import { AuthContext } from "context-api/auth-provider";
import { Campaign } from "models/Campaign";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledCampaignList = styled.div`
  height: 100%;

  .back-wrapper {
    width: 240px;
    margin-right: auto;

    button {
      justify-content: left;
    }
  }
`;

type CampaignListProps = {
  onClick: (id: number) => void;
};

export const CampaignList = (props: CampaignListProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/campaign`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        setIsLoading(false);
        setCampaigns(res.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
        if (axios.isAxiosError(err)) {
          toast.error(err.response?.data.error);
        } else {
          toast.error(err.message);
        }
      });
  }, []);

  const onSearch = (text: string) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/api/campaign/search/${text}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        setIsLoading(false);
        setCampaigns(res.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
        if (axios.isAxiosError(err)) {
          toast.error(err.response?.data.error);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <StyledCampaignList>
      <AppSpinner show={isLoading}></AppSpinner>
      <div className="back-wrapper mb-4">
        <StyledLink to="/">
          <StyledButton>⬅️ Back</StyledButton>
        </StyledLink>
      </div>

      <ItemList
        items={campaigns.map((c) => ({
          id: c.id as number,
          image: c.campaignImage as string,
          status:
            c.status === 0 ? "Draft" : c.status === 1 ? "Published" : "Closed",
          subtitle: c.campaignDescription,
          title: c.campaignTitle,
        }))}
        activeItemId={-1}
        search={onSearch}
        selectionChange={(item) => props.onClick(item.id)}
      />
    </StyledCampaignList>
  );
};
