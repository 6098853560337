import { colors } from "components/variables";
import { Form, Modal, Spinner, SpinnerProps } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FullWidthImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledButton = styled.button<{
  backgroundColor?: string;
  color?: string;
  border?: string;
  borderRadius?: string;
}>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.backgroundColor || "transparent"};
  color: ${(props) => props.color || colors.black};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || 0};
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  line-height: 3rem;

  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    box-shadow: 2px 2px 20px 0px #000;

    .modal-body {
      padding: 1.4rem 2.5rem 2.5rem;
    }
  }
`;

const StyledCarouselWrapper = styled.div`
  padding: 0 25px;

  .slick-list {
    padding: 1.5rem 0.75rem;
  }

  .slick-arrow {
    z-index: 99;
    &::before {
      font-size: 2rem;
      color: darkblue;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledFormControl = styled(Form.Control)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
`;

const StyledSpinner = (props) => {
  return (
    <div className="spinner-container">
      <Spinner {...props}></Spinner>
    </div>
  );
};

const StyledFullScreenSpinnerContainer = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.$show ? "block" : "none")};
  transition: all 0.5s linear;
  background: #c2c5cd8f;

  .spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const AppSpinner = (props: { show: boolean }) => {
  const { show, ...spinnerProps } = props;

  return (
    <StyledFullScreenSpinnerContainer
      $show={props.show}
      className="spinner-cont"
    >
      <StyledSpinner {...spinnerProps} />
    </StyledFullScreenSpinnerContainer>
  );
};

export {
  FullWidthImage,
  StyledButton,
  StyledModal,
  StyledCarouselWrapper,
  StyledLink,
  StyledFormControl,
  StyledFullScreenSpinnerContainer,
  AppSpinner,
};
