import instance from "axiosClient";
import { Product } from "models/Product";

const productUrl = `product`;

export const getProducts = (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Product[]>(productUrl, config);
};

export const addProduct = (product: FormData, token: string) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.post(productUrl, product, config);
};

export const updateProduct = (
  id: number,
  product: Pick<Product, "productName" | "description" | "price" | "isActive">,
  token: string
) => {
  const url = `${productUrl}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.put(url, product, config);
};

export const searchProducts = (text: string, token: string) => {
  const url = `${productUrl}/search?search=${text}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.post<Product[]>(url, null, config);
};

export const deleteProduct = (id: number, token: string) => {
  const url = `${productUrl}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.delete(url, config);
};
