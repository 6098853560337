import { useNavigate } from "react-router-dom";
import { CampaignList } from "./campaigns";

export const ManageSales = () => {
  const navigate = useNavigate();

  const onCampaignSelect = (id: number) => {
    navigate(`./${id}`);
  };

  return <CampaignList onClick={onCampaignSelect}></CampaignList>;
};
