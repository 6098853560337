import {
  AppSpinner,
  StyledButton,
} from "components/common-styled-components/styled-components";
import { colors } from "components/variables";
import { UserTicket } from "models/Ticket";
import { Winner } from "models/Winner";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";

const StyledWinnerWrapper = styled.div``;

export type SelectWinnerProps = {
  tickets: UserTicket[];
  offerId: number;
  winner?: Winner;
  onAdd: (ticketNumber: string, offerId: number) => void;
  delete: (id: number) => void;
};

export const SelectWinnerComponent = (props: SelectWinnerProps) => {
  const { register, getValues, watch, setValue, reset } = useForm<{
    ticketNumber: string;
  }>({
    defaultValues: {
      ticketNumber: props.winner?.ticketNumber,
    },
  });

  useEffect(() => {
    if (props.winner?.ticketNumber) {
      setTimeout(() => {
        setValue("ticketNumber", props.winner!.ticketNumber);
      });
    }
  }, [props.winner]);

  const selectedTicketNumber = watch("ticketNumber");
  const selectedTicket = props.tickets.find(
    (t) => t.ticketNumber === selectedTicketNumber
  );

  const add = () => {
    const ticketNumber = getValues("ticketNumber");

    props.onAdd(ticketNumber, props.offerId);
  };

  const onDelete = () => {
    props.delete(props.winner!.id);
    reset();
  };

  return (
    <StyledWinnerWrapper>
      <Form.Group className="mb-3" controlId="status">
        <Form.Label>Ticket</Form.Label>
        <Form.Select
          {...register("ticketNumber")}
          disabled={!!props.winner?.ticketNumber}
        >
          <option value=""></option>
          {props.tickets.map((ticket, i) => (
            <option value={ticket.ticketNumber} key={i}>
              {ticket.ticketNumber}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {selectedTicket ? (
        <div className="mb-2">
          <p>
            {selectedTicket.firstName} {selectedTicket.lastName}
          </p>
          <p>{selectedTicket.nic}</p>
          <p>{selectedTicket.phoneNumber}</p>
        </div>
      ) : null}

      {props.winner ? (
        <StyledButton
          backgroundColor={colors.colorMain}
          color={colors.white}
          className="mb-3 w-50"
          onClick={onDelete}
        >
          Delete
        </StyledButton>
      ) : (
        <StyledButton
          backgroundColor={colors.colorMain}
          color={colors.white}
          className="mb-3 w-50"
          onClick={add}
        >
          Add
        </StyledButton>
      )}
    </StyledWinnerWrapper>
  );
};
