import { Item } from "models/Item";
import { useState } from "react";
import styled from "styled-components";
import {
  StyledButton,
  StyledFormControl,
} from "./common-styled-components/styled-components";
import { ItemCardLarge } from "./item-card-large";
import { colors } from "./variables";

const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .item-wrapper {
    flex: 1;
    overflow-y: auto;
    min-height: 0;
  }
`;

const StyledSearchWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 35%;

  .input {
    flex: 1 1 40%;
  }
  button {
    flex: 1 1 40%;
  }
`;

const stat = [
  { title: "Total items", value: 2500 },
  { title: "Items sold", value: 1300 },
  { title: "Number of offers", value: 2500 },
  { title: "Published date", value: "2/12/2023" },
  { title: "Closing date", value: "2/12/2023" },
];

export const ItemList = (props: {
  items: Item[];
  selectionChange: (item: Item) => void;
  search: (text: string) => void;
  activeItemId: string | number;
}) => {
  const [searchText, setSearchText] = useState("");

  const onItemClick = (item: Item) => {
    props.selectionChange(item);
  };

  const onSearch = (query: string) => {
    props.search(query);
  };

  return (
    <StyledListWrapper>
      <StyledSearchWrapper className="mb-3">
        <StyledFormControl
          type="text"
          placeholder="Name"
          onChange={(e) => setSearchText(e.target.value)}
        ></StyledFormControl>
        <StyledButton
          color={colors.white}
          backgroundColor={colors.colorMain}
          onClick={() => onSearch(searchText)}
        >
          Search
        </StyledButton>
      </StyledSearchWrapper>

      <div className="item-wrapper">
        {props.items?.map((item, index) => (
          <div
            key={index}
            style={{ cursor: "pointer" }}
            className="mb-2"
            onClick={() => {
              onItemClick(item);
            }}
          >
            <ItemCardLarge
              item={item}
              isActive={props.activeItemId === item.id}
            />
          </div>
        ))}
      </div>
    </StyledListWrapper>
  );
};
