import { AddManageCampaignComponent } from "pages/add-mange-campaign";
import { AddManageOfferComponent } from "pages/add-manage-offers";
import { AddAndManageProductComponent } from "pages/add-manage-product";
import { HomeComponent } from "pages/home";
import { LoginComponent } from "pages/login";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./pages/error/error-page";
import { CampaignList } from "pages/campaigns";
import { PurchaseHistory } from "pages/purchase-history";
import { Tickets } from "pages/tickets";
import { ManageDrawComponent } from "pages/manage-draw";
import { ManageSales } from "pages/manage-sales";
import { ManageTickets } from "pages/manage-tickets";
import { AddManageWinners } from "pages/manage-winners/add-manage-winners";

export const router = createBrowserRouter([
  {
    path: "login",
    element: <LoginComponent></LoginComponent>,
  },
  {
    path: "",
    element: <App></App>,
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      { path: "/", element: <HomeComponent></HomeComponent> },
      {
        path: "/add-product",
        element: <AddAndManageProductComponent></AddAndManageProductComponent>,
      },
      {
        path: "/manage-products",
        element: (
          <AddAndManageProductComponent
            isManage={true}
          ></AddAndManageProductComponent>
        ),
      },
      {
        path: "/add-offer",
        element: <AddManageOfferComponent></AddManageOfferComponent>,
      },
      {
        path: "/manage-offer",
        element: (
          <AddManageOfferComponent isManage={true}></AddManageOfferComponent>
        ),
      },
      {
        path: "/add-campaign",
        element: <AddManageCampaignComponent></AddManageCampaignComponent>,
      },
      {
        path: "/manage-campaign",
        element: (
          <AddManageCampaignComponent
            isManage={true}
          ></AddManageCampaignComponent>
        ),
      },
      {
        path: "/sales",
        children: [
          {
            path: "",
            element: <ManageSales></ManageSales>,
          },
          {
            path: ":campaignId",
            loader: ({ params }) => {
              return params.campaignId;
            },
            element: <PurchaseHistory />,
          },
        ],
      },
      {
        path: "/tickets",
        children: [
          {
            path: "",
            element: <ManageTickets />,
          },
          {
            path: ":campaignId",
            loader: ({ params }) => {
              return params.campaignId;
            },
            element: <Tickets />,
          },
        ],
      },
      {
        path: "/draws",
        element: <ManageDrawComponent />,
      },
      {
        path: "/winners",
        element: <AddManageWinners />,
      },
    ],
  },
]);
