import instance from "axiosClient";
import { UserTicket } from "models/Ticket";
import { Winner, WinnerPayload } from "models/Winner";

const baseUrl = "ticket";

export const getWinnersForCampaign = (campaignId: number, token: string) => {
  const url = `${baseUrl}/winners/${campaignId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Winner[]>(url, config);
};

export const getTicketsForCampaign = (campaignId: number, token: string) => {
  const url = `${baseUrl}/campaign/${campaignId}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<UserTicket[]>(url, config);
};

export const addWinner = (payload: WinnerPayload, token: string) => {
  const url = `${baseUrl}/winner`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.post(url, payload, config);
};

export const deleteWinner = (id: number, token: string) => {
  const url = `${baseUrl}/winner/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.delete(url, config);
};
