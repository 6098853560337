import styled from "styled-components";

const DefaultAddManageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .back-wrapper {
    width: 240px;
    margin-right: auto;

    button {
      justify-content: left;
    }
  }

  .page-content {
    display: flex;
    flex: 1;
    min-height: 0;

    .sidebar {
      flex: 1 1 50%;
      margin-right: 2rem;
    }

    .product-info {
      flex: 1 1 60%;

      @media screen and (max-width: 1024px) {
        flex-basis: 30%;
      }

      .actions {
        display: flex;
        gap: 2rem;
      }

      .add-container {
        width: 30%;
      }

      .manage-container {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
        button {
          width: 40%;
        }
      }
    }

    .product-image {
      flex: 1 1 30%;

      @media screen and (max-width: 1024px) {
        flex-basis: 20%;
      }

      .upload-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image-container {
          width: 360px;

          .preview {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .upload-image-btn {
          width: 360px;
        }

        @media screen and (max-width: 1024px) {
          .image-container {
            width: 200px;
          }
          .upload-image-btn {
            width: 200px;
          }
        }
      }
    }
  }
`;

export { DefaultAddManageWrapper };
