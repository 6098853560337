const colors = {
  colorMain: "#9A0000",
  accent: "#00000033",
  white: "#FFF",
  black: "#000",
  backgroundColor: "#F2F5F8",
  orange: "#F3A833",
  lightRed: "#FD594D",
  lightPurple: "#7B4DFD",
  mediumGreen: "#259f45",
};

const styles = {
  boxShadow: `0px 2px 15px -5px ${colors.accent}`,
};

export { colors, styles };
