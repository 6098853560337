import { Item } from "models/Item";
import { Product } from "models/Product";
import { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import {
  StyledButton,
  StyledFormControl,
} from "./common-styled-components/styled-components";
import { ItemCardComponent } from "./item-card";
import { colors } from "./variables";

const StyledSidebarWraper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .item-wrapper {
    flex: 1;
    overflow-y: auto;
    min-height: 0;
  }
`;

const StyledSearchWrapper = styled.div`
  display: flex;
  gap: 1rem;

  .input {
    flex: 1 1 40%;
  }
  button {
    flex: 1 1 40%;
  }
`;

export const ItemSidebarComponent = (props: {
  items: Item[];
  selectionChange: (item: Item) => void;
  search: (text: string) => void;
  activeItemId: string | number;
}) => {
  const [searchText, setSearchText] = useState("");

  const onItemClick = (item: Item) => {
    props.selectionChange(item);
  };

  const onSearch = (query: string) => {
    props.search(query);
  };

  return (
    <StyledSidebarWraper>
      <StyledSearchWrapper className="mb-3">
        <StyledFormControl
          type="text"
          placeholder="Name"
          onChange={(e) => setSearchText(e.target.value)}
        ></StyledFormControl>
        <StyledButton
          color={colors.white}
          backgroundColor={colors.colorMain}
          onClick={() => onSearch(searchText)}
        >
          Search
        </StyledButton>
      </StyledSearchWrapper>

      <div className="item-wrapper">
        {props.items?.map((item, index) => (
          <div
            key={index}
            style={{ cursor: "pointer" }}
            className="mb-2"
            onClick={() => {
              onItemClick(item);
            }}
          >
            <ItemCardComponent
              item={item}
              isActive={props.activeItemId === item.id}
            />
          </div>
        ))}
      </div>
    </StyledSidebarWraper>
  );
};
