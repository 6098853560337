import {
  addProduct,
  deleteProduct,
  getProducts,
  searchProducts,
  updateProduct,
} from "api/product";
import placeholder from "assets/images/placeholder.webp";
import {
  AppSpinner,
  StyledButton,
  StyledFormControl,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { ItemSidebarComponent } from "components/items-sidebar";
import { colors } from "components/variables";
import { AuthContext } from "context-api/auth-provider";
import { Item } from "models/Item";
import { Product } from "models/Product";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledAddProductWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .back-wrapper {
    width: 240px;
    margin-right: auto;

    button {
      justify-content: left;
    }
  }

  .page-content {
    display: flex;
    flex: 1;
    min-height: 0;

    .sidebar {
      flex: 1 1 50%;
      margin-right: 2rem;
    }

    .product-info {
      flex: 1 1 60%;

      @media screen and (max-width: 1024px) {
        flex-basis: 30%;
      }

      .add-container {
        width: 30%;
      }

      .manage-container {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
        button {
          width: 40%;
        }
      }
    }

    .product-image {
      flex: 1 1 30%;

      @media screen and (max-width: 1024px) {
        flex-basis: 20%;
      }

      .upload-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image-container {
          width: 360px;

          .preview {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .upload-image-btn {
          width: 360px;
        }

        @media screen and (max-width: 1024px) {
          .image-container {
            width: 200px;
          }
          .upload-image-btn {
            width: 200px;
          }
        }
      }
    }
  }
`;

type AddProductFormType = {
  name: string;
  nameSi: string;
  nameTa: string;
  description: string;
  descriptionSi: string;
  descriptionTa: string;
  price: number;
  isActive?: string;
};

export const AddAndManageProductComponent = (props: { isManage?: boolean }) => {
  const { register, setValue, handleSubmit, reset } =
    useForm<AddProductFormType>();
  const fileUpload = useRef<HTMLInputElement>(null);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState("");
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSelectionChanged = (selectedItem: Item) => {
    const selected = products.find((p) => p.id === selectedItem.id)!;
    setSelectedItem(selectedItem);
    setValue("description", selected.description);
    setValue("descriptionSi", selected.descriptionSi);
    setValue("descriptionTa", selected.descriptionTa);
    setValue("price", selected.price);
    setValue("name", selected.productName);
    setValue("nameSi", selected.productNameSi);
    setValue("nameTa", selected.productNameTa);
    setValue("isActive", selected.isActive ? "true" : "false");
  };

  useEffect(() => {
    setIsLoading(true);

    getProducts(token)
      .then((res) => {
        setProducts(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, []);

  const onSearchTextChanged = async (searchText: string) => {
    setIsLoading(true);
    setSearchText(searchText);
    setSelectedItem(null);

    try {
      const response = await searchProducts(searchText, token);

      if (response.data.length === 0) {
        toast.error(`No products found for '${searchText}'`);
      }
      setProducts(response.data);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;
    if (files) {
      setFile(files[0]);
      const reader = new FileReader();
      const url = reader.readAsDataURL(files[0]);
      reader.onloadend = (e: any) => {
        setFileUrl(reader.result as string);
      };
    }
  };

  const handleAddProduct = async (product: AddProductFormType) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("productName", product.name);
    formData.append("productNameSi", product.nameSi);
    formData.append("productNameTa", product.nameTa);
    formData.append("description", product.description);
    formData.append("descriptionSi", product.descriptionSi);
    formData.append("descriptionTa", product.descriptionTa);
    formData.append("price", `${product.price}`);
    formData.append("image", file as Blob);

    try {
      await addProduct(formData, token);
      setIsLoading(false);
      toast.success("Product added");
      navigate("/");
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const handleUpdateProduct = async (product: AddProductFormType) => {
    setIsLoading(true);
    const payload = {
      productName: product.name,
      productNameSi: product.nameSi,
      productNameTa: product.nameTa,
      description: product.description,
      descriptionSi: product.descriptionSi,
      descriptionTa: product.descriptionTa,
      price: product.price,
      isActive: product.isActive === "true", // form bound value is converted to string
    };

    try {
      const res = await updateProduct(selectedItem?.id!, payload, token);
      setIsLoading(false);
      toast.success("Product updated");
      const updatedProductIndex = products.findIndex(
        (product) => product.id === selectedItem?.id
      );
      const productsCopy = [...products];
      const oldProduct = productsCopy[updatedProductIndex];
      const newProduct: Product = {
        ...oldProduct,
        productName: payload.productName,
        productNameSi: payload.productNameSi,
        productNameTa: payload.productNameTa,
        description: payload.description,
        descriptionSi: payload.descriptionSi,
        descriptionTa: payload.descriptionTa,
        price: payload.price,
        isActive: payload.isActive, // form bound value is converted to string
      };
      productsCopy[updatedProductIndex] = newProduct;
      setProducts(productsCopy);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      await deleteProduct(selectedItem?.id!, token);
      setIsLoading(false);
      toast.success("Product deleted");
      setProducts(
        products.filter((product) => product.id !== selectedItem?.id)
      );
      reset();
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  return (
    <StyledAddProductWrapper className="product-page-wrapper">
      <AppSpinner show={isLoading}></AppSpinner>
      <div className="back-wrapper mb-4">
        <StyledLink to="/">
          <StyledButton>⬅️ Back</StyledButton>
        </StyledLink>
      </div>

      <h2 className="page-title mb-4">
        {props.isManage ? "Manage" : "Add"} Products
      </h2>

      <div className="page-content">
        {props.isManage ? (
          <div className="sidebar">
            <ItemSidebarComponent
              items={products.map((product) => ({
                id: product.id,
                image: product.imageName,
                status: product.isActive ? "Active" : "Inactive",
                title: product.productName,
                subtitle: `${product.price.toFixed(2)} Rs`,
              }))}
              selectionChange={onSelectionChanged}
              activeItemId={selectedItem?.id as number}
              search={onSearchTextChanged}
            ></ItemSidebarComponent>
          </div>
        ) : null}

        <div className="product-info">
          <Form
            onSubmit={handleSubmit(
              props.isManage ? handleUpdateProduct : handleAddProduct
            )}
          >
            <fieldset disabled={props.isManage ? selectedItem === null : false}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name - English</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("name")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="nameSi">
                <Form.Label>Name - Sinhala</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("nameSi")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="nameTa">
                <Form.Label>Name - Tamil</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("nameTa")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="desc">
                <Form.Label>Description - English</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("description")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="descSi">
                <Form.Label>Description - Sinhala</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("descriptionSi")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="descTa">
                <Form.Label>Description - Tamil</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("descriptionTa")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="price">
                <Form.Label>Price</Form.Label>
                <StyledFormControl
                  type="number"
                  placeholder="Price"
                  step="0.01"
                  max="9999999999.99"
                  {...register("price", {
                    valueAsNumber: true,
                  })}
                />
              </Form.Group>

              {props.isManage ? (
                <Form.Group className="mb-3" controlId="price">
                  <Form.Select {...register("isActive")}>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Form.Select>
                </Form.Group>
              ) : null}

              {props.isManage ? (
                <div className="manage-container">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                    type="button"
                    onClick={handleDelete}
                  >
                    Delete
                  </StyledButton>
                  <StyledButton
                    backgroundColor={colors.accent}
                    color={colors.black}
                    type="submit"
                  >
                    Update
                  </StyledButton>
                </div>
              ) : (
                <div className="add-container">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                    type="submit"
                  >
                    Add
                  </StyledButton>
                </div>
              )}
            </fieldset>
          </Form>
        </div>
        {props.isManage ? null : (
          <div className="product-image">
            <div className="upload-container">
              <div className="image-container mb-3">
                <img className="preview" src={fileUrl || placeholder} />
              </div>
              <div className="upload-image-btn">
                <StyledButton
                  color={colors.white}
                  backgroundColor={colors.colorMain}
                  onClick={() => {
                    fileUpload.current?.click();
                  }}
                >
                  Upload
                </StyledButton>
              </div>
            </div>

            <input
              className="d-none"
              type="file"
              onChange={(e) => handleChange(e)}
              ref={fileUpload}
            />
          </div>
        )}
      </div>
    </StyledAddProductWrapper>
  );
};
