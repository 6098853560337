import {
  addOffer,
  deleteOffer,
  getOffers,
  searchOffers,
  updateOffer,
} from "api/offer";
import placeholder from "assets/images/placeholder.webp";
import {
  AppSpinner,
  StyledButton,
  StyledFormControl,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { ItemSidebarComponent } from "components/items-sidebar";
import { DefaultAddManageWrapper } from "components/layout";
import { colors } from "components/variables";
import { AuthContext } from "context-api/auth-provider";
import { Item } from "models/Item";
import { Offer } from "models/Offer";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledLayout = styled(DefaultAddManageWrapper)``;

type OfferFormType = {
  name: string;
  nameSi: string;
  nameTa: string;
  description: string;
  descriptionSi: string;
  descriptionTa: string;
  specification: string;
  price: number;
  type: string;
  isActive: string;
};

export const AddManageOfferComponent = (props: { isManage?: boolean }) => {
  const { register, setValue, handleSubmit, reset } = useForm<OfferFormType>();
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState<File>();
  const [searchText, setSearchText] = useState("");
  const fileUpload = useRef<HTMLInputElement>(null);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSelectionChanged = (item: Item) => {
    const selected = offers.find((p) => p.id === item.id)!;
    setSelectedItem(item);
    setValue("name", selected.offerTitle);
    setValue("nameSi", selected.offerTitleSi);
    setValue("nameTa", selected.offerTitleTa);
    setValue("description", selected.description);
    setValue("descriptionSi", selected.descriptionSi);
    setValue("descriptionTa", selected.descriptionTa);
    setValue("specification", selected.specification);
    setValue("price", selected.price);
    setValue("type", selected.offerType);
    setValue("isActive", selected.isActive ? "true" : "false");
  };

  useEffect(() => {
    setIsLoading(true);
    getOffers(token)
      .then((res) => {
        setOffers(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, []);

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      await deleteOffer(selectedItem?.id!, token);
      setIsLoading(false);
      toast.success(`Offer deleted`);
      setOffers(offers.filter((o) => o.id !== selectedItem?.id));
      reset();
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleUpdateoffer = async (form: OfferFormType) => {
    setIsLoading(true);
    const payload: Offer = {
      offerTitle: form.name,
      offerTitleSi: form.nameSi,
      offerTitleTa: form.nameTa,
      description: form.description,
      descriptionSi: form.descriptionSi,
      descriptionTa: form.descriptionTa,
      specification: form.specification,
      price: form.price,
      offerType: form.type,
      isActive: form.isActive === "true",
      id: selectedItem?.id as number,
    };

    try {
      await updateOffer(selectedItem?.id!, payload, token);
      setIsLoading(false);
      toast.success(`Offer updated`);
      const updatedOfferIndex = offers.findIndex(
        (offer) => offer.id === selectedItem?.id
      );
      const offersCopy = [...offers];
      const oldOffer = offersCopy[updatedOfferIndex];
      const newOffer: Offer = {
        ...oldOffer,
        description: payload.description,
        descriptionSi: payload.descriptionSi,
        descriptionTa: payload.descriptionTa,
        specification: payload.specification,
        price: payload.price,
        offerTitle: payload.offerTitle,
        offerTitleSi: payload.offerTitleSi,
        offerTitleTa: payload.offerTitleTa,
        offerType: payload.offerType,
        isActive: payload.isActive,
      };
      offersCopy[updatedOfferIndex] = newOffer;
      setOffers(offersCopy);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const handleAddOffer = async (form: OfferFormType) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("offerTitle", form.name);
    formData.append("offerTitleSi", form.nameSi);
    formData.append("offerTitleTa", form.nameTa);
    formData.append("description", form.description);
    formData.append("descriptionSi", form.descriptionSi);
    formData.append("descriptionTa", form.descriptionTa);
    formData.append("specification", form.specification);
    formData.append("offerType", `${form.type}`);
    formData.append("price", `${form.price}`);
    formData.append("image", file as Blob);

    try {
      await addOffer(formData, token);
      setIsLoading(false);
      toast.success("Offer added");
      navigate("/");
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const onSearchTextChanged = async (text: string) => {
    setIsLoading(true);
    setSearchText(text);
    setSelectedItem(null);

    try {
      const response = await searchOffers(text, token);
      setIsLoading(false);
      if (response.data.length === 0) {
        toast.error(`No products found for '${text}'`);
      }
      setOffers(response.data);
    } catch (err: any) {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;
    if (files) {
      setFile(files[0]);
      const reader = new FileReader();
      const url = reader.readAsDataURL(files[0]);
      reader.onloadend = (e: any) => {
        setFileUrl(reader.result as string);
      };
    }
  };

  return (
    <StyledLayout>
      <AppSpinner show={isLoading}></AppSpinner>
      <div className="back-wrapper mb-4">
        <StyledLink to="/">
          <StyledButton>⬅️ Back</StyledButton>
        </StyledLink>
      </div>

      <h2 className="page-title mb-4">
        {props.isManage ? "Manage Offers" : "Add Offer"}
      </h2>

      <div className="page-content">
        {props.isManage ? (
          <div className="sidebar">
            <ItemSidebarComponent
              items={offers.map((offer) => ({
                id: offer.id as number,
                image: offer.offerImage as string,
                title: offer.offerTitle,
                status: offer.isActive ? "Actice" : "Inactive",
                subtitle: `${offer.price.toFixed(2)} Rs`,
              }))}
              selectionChange={onSelectionChanged}
              activeItemId={selectedItem?.id as number}
              search={onSearchTextChanged}
            ></ItemSidebarComponent>
          </div>
        ) : null}

        <div className="product-info">
          <Form
            onSubmit={handleSubmit(
              props.isManage ? handleUpdateoffer : handleAddOffer
            )}
          >
            <fieldset disabled={props.isManage ? selectedItem === null : false}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name - English</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("name")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="nameSi">
                <Form.Label>Name - Sinhala</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("nameSi")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="nameTa">
                <Form.Label>Name - Tamil</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("nameTa")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="desc">
                <Form.Label>Description - English</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("description")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="descSi">
                <Form.Label>Description - Sinhala</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("descriptionSi")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="descTa">
                <Form.Label>Description - Tamil</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("descriptionTa")}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="specification">
                <Form.Label>Specification</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={5}
                  {...register("specification", { maxLength: 50 })}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="type">
                <Form.Select {...register("type")}>
                  <option value="0">Main offer</option>
                  <option value="1">Sub offer</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="price">
                <Form.Label>Price</Form.Label>
                <StyledFormControl
                  type="number"
                  placeholder="Price"
                  step="0.01"
                  max="9999999999.99"
                  {...register("price", {
                    valueAsNumber: true,
                  })}
                />
              </Form.Group>

              {props.isManage ? (
                <Form.Group className="mb-3" controlId="price">
                  <Form.Select {...register("isActive")}>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Form.Select>
                </Form.Group>
              ) : null}

              {props.isManage ? (
                <div className="manage-container">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                    type="button"
                    onClick={handleDelete}
                  >
                    Delete
                  </StyledButton>
                  <StyledButton
                    backgroundColor={colors.accent}
                    color={colors.black}
                    type="submit"
                  >
                    Update
                  </StyledButton>
                </div>
              ) : (
                <div className="add-container">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                    type="submit"
                  >
                    Add
                  </StyledButton>
                </div>
              )}
            </fieldset>
          </Form>
        </div>

        {props.isManage ? null : (
          <div className="product-image">
            <div className="upload-container">
              <div className="image-container mb-3">
                <img className="preview" src={fileUrl || placeholder} />
              </div>
              <div className="upload-image-btn">
                <StyledButton
                  color={colors.white}
                  backgroundColor={colors.colorMain}
                  onClick={() => {
                    fileUpload.current?.click();
                  }}
                >
                  Upload
                </StyledButton>
              </div>
            </div>

            <input
              className="d-none"
              type="file"
              onChange={(e) => handleFileChange(e)}
              ref={fileUpload}
            />
          </div>
        )}
      </div>
    </StyledLayout>
  );
};
