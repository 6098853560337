import {
  StyledButton,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { colors } from "components/variables";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledHomeWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }

  .content-left {
    margin-right: 10%;
  }

  .section {
    text-align: left;

    .actions {
      display: flex;
      gap: 2rem;
      .btn-wrapper {
        width: 150px;
        height: 100px;

        @media screen and (max-width: 1024px) {
          width: 100px;
          height: 100px;
        }

        @media screen and (max-width: 996px) {
          width: auto;
        }
      }
    }
  }
`;

export const HomeComponent = () => {
  return (
    <StyledHomeWrapper>
      <div className="content-left">
        <div className="content-wrapper">
          <div className="products section mb-4">
            <h3 className="mb-4">Products</h3>

            <div className="actions">
              <div className="btn-wrapper">
                <StyledLink to="add-product">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Add Product
                  </StyledButton>
                </StyledLink>
              </div>
              <div className="btn-wrapper">
                <StyledLink to="manage-products">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Manage Products
                  </StyledButton>
                </StyledLink>
              </div>
            </div>
          </div>
          <div className="offers section mb-4">
            <h3 className="mb-4">Offers</h3>
            <div className="actions">
              <div className="btn-wrapper">
                <StyledLink to="add-offer">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Add Offer
                  </StyledButton>
                </StyledLink>
              </div>
              <div className="btn-wrapper">
                <StyledLink to="manage-offer">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Manage Offers
                  </StyledButton>
                </StyledLink>
              </div>
            </div>
          </div>
          <div className="campaign section mb-4">
            <h3 className="mb-4">Campaigns</h3>
            <div className="actions">
              <div className="btn-wrapper">
                <StyledLink to="add-campaign">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Add Campaign
                  </StyledButton>
                </StyledLink>
              </div>
              <div className="btn-wrapper">
                <StyledLink to="manage-campaign">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Manage Campaigns
                  </StyledButton>
                </StyledLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-right">
        <div className="content-wrapper">
          <div className="product-sale section mb-4">
            <h3 className="mb-4">Product Sale</h3>

            <div className="actions">
              <div className="btn-wrapper">
                <StyledLink to="sales">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Manage Sales
                  </StyledButton>
                </StyledLink>
              </div>
            </div>
          </div>
          <div className="tickets section mb-4">
            <h3 className="mb-4">Tickets</h3>
            <div className="actions">
              <div className="btn-wrapper">
                <StyledLink to="tickets">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Manage tickets
                  </StyledButton>
                </StyledLink>
              </div>
            </div>
          </div>
          <div className="draw section mb-4">
            <h3 className="mb-4">Draw</h3>

            <div className="actions">
              <div className="btn-wrapper">
                <StyledLink to="draws">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Manage Draws
                  </StyledButton>
                </StyledLink>
              </div>
              <div className="btn-wrapper">
                <StyledLink to="winners">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Add/Manage Winners
                  </StyledButton>
                </StyledLink>
              </div>
              {/* <div className="btn-wrapper">
                <StyledLink to="manage-winners">
                  <StyledButton
                    backgroundColor={colors.colorMain}
                    color={colors.white}
                  >
                    Manage Winners
                  </StyledButton>
                </StyledLink>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </StyledHomeWrapper>
  );
};
