import { login } from "api/auth";
import { ReactComponent as Eye } from "assets/images/remove_red_eye.svg";
import { StyledButton } from "components/common-styled-components/styled-components";
import { colors } from "components/variables";
import { AuthContext } from "context-api/auth-provider";
import { UserRegistration } from "models/User";
import { useContext, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledFormControl = styled(Form.Control)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
`;

const StyledInput = styled(InputGroup)`
  .dropdown-toggle {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-right: none;
    border-color: #ced4da;
    background-color: ${colors.white};
    color: ${colors.black};
  }

  .form-control {
    border-left: none;

    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }
`;

const StyledAuthWrapper = styled.div`
  width: 400px;
  padding-top: 50px;
  margin: auto;

  h2 {
    font-weight: 500;
  }

  p {
    font-weight: 500;
  }

  label {
    font-weight: 500;
  }

  .submit {
    height: 4rem;
  }

  .link {
    color: ${colors.colorMain};
    cursor: pointer;
  }

  .link-login {
    text-align: center;
    margin-top: 1rem;
  }
`;

const ShowPasswordToggle = styled.div<{ isToggled: boolean }>`
  position: absolute;
  top: 0;
  right: 15px;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;

  --eye-color-toggled: #0c16ad;

  svg {
    transition: all 0.1s linear;

    ${(props) =>
      props.isToggled
        ? `
          g {
            fill: var(--eye-color-toggled);
        }

        path {
            stroke: var(--eye-color-toggled);
            fill: transparent;
        }        
        `
        : ``}
  }
`;

export const LoginComponent = () => {
  const { register, handleSubmit } = useForm<UserRegistration>();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const { setToken } = useContext(AuthContext);

  const onLogin = async (data: UserRegistration) => {
    const response = await login(data);
    setToken(response.data.token);
    toast.success("Logged in");
    navigate("/");
  };

  return (
    <StyledAuthWrapper>
      <h2>Login</h2>
      <p>
        Create your account - enjoy our services with most important features
      </p>
      <Form onSubmit={handleSubmit(onLogin)}>
        <Form.Group className="mb-3" controlId="email">
          <StyledFormControl
            type="email"
            placeholder="Email"
            {...register("emailAddress")}
          />
        </Form.Group>
        <Form.Group
          className="mb-3"
          controlId="password"
          style={{ position: "relative" }}
        >
          <StyledFormControl
            type={passwordVisible ? "text" : "password"}
            placeholder="Password"
            {...register("password")}
          />
          <ShowPasswordToggle
            isToggled={passwordVisible}
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            <Eye />
          </ShowPasswordToggle>
        </Form.Group>

        <div className="form-group submit">
          <StyledButton
            color={colors.white}
            backgroundColor={colors.colorMain}
            borderRadius="10px"
            type="submit"
          >
            Login
          </StyledButton>
        </div>
      </Form>
    </StyledAuthWrapper>
  );
};
