import { getCampaigns, searchCampaigns } from "api/campaign";
import {
  addWinner,
  deleteWinner,
  getTicketsForCampaign,
  getWinnersForCampaign,
} from "api/ticket";
import axios from "axios";
import {
  AppSpinner,
  StyledButton,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { ItemSidebarComponent } from "components/items-sidebar";
import { DefaultAddManageWrapper } from "components/layout";
import { AuthContext } from "context-api/auth-provider";
import { Campaign } from "models/Campaign";
import { Item } from "models/Item";
import { UserTicket } from "models/Ticket";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import styled from "styled-components";
import { SelectWinnerComponent } from "./select-winner";
import { Winner, WinnerPayload } from "models/Winner";

const StyledLayout = styled(DefaultAddManageWrapper)`
  .page-content {
    gap: 3rem;
    overflow-y: auto;
  }

  .winners {
    flex: 0 50%;
  }
`;

export const AddManageWinners = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [winners, setWinners] = useState<Winner[]>([]);
  const [searchText, setSearchText] = useState("");
  const { token } = useContext(AuthContext);
  const selectedCampaign = campaigns?.find((c) => c.id === selectedItem?.id);
  const [tickets, setTickets] = useState<UserTicket[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getCampaigns(token)
      .then((res) => {
        setIsLoading(false);
        setCampaigns(res.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, []);

  const onSelectionChanged = async (item: Item) => {
    setSelectedItem(item);
    setIsLoading(true);
    getTicketsForCampaign(item.id, token).then((res) => {
      setTickets(res.data);
    });

    const res = await getWinnersForCampaign(item.id, token);
    setWinners(res.data);
    setIsLoading(false);
  };

  const onSearchTextChanged = async (text: string) => {
    setIsLoading(true);
    setSearchText(text);
    setSelectedItem(null);

    try {
      const response = await searchCampaigns(text, token);
      setIsLoading(false);
      if (response.data.length === 0) {
        toast.error(`No products found for '${text}'`);
      }
      setCampaigns(response.data);
    } catch (err: any) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        toast.error(err.response?.data.error);
      } else {
        toast.error(err.message);
      }
    }
  };

  const onAdd = async (ticketNumber: string, offerId: number) => {
    const ticket = tickets.find((t) => t.ticketNumber === ticketNumber);

    if (selectedCampaign && ticket) {
      const payload: WinnerPayload = {
        campaignId: selectedCampaign?.id!,
        offerId: offerId,
        ticketId: ticket?.id,
        userId: ticket.userId,
      };
      setIsLoading(true);
      await addWinner(payload, token);
      const res = await getWinnersForCampaign(selectedCampaign?.id!, token);
      setWinners(res.data);
      setIsLoading(false);
    }
  };

  const onDelete = async (id: number) => {
    setIsLoading(true);
    await deleteWinner(id, token);
    const res = await getWinnersForCampaign(selectedItem!.id, token);
    setWinners(res.data);
    setIsLoading(false);
  };

  return (
    <StyledLayout>
      <AppSpinner show={isLoading}></AppSpinner>
      <div className="back-wrapper mb-4">
        <StyledLink to="/">
          <StyledButton>⬅️ Back</StyledButton>
        </StyledLink>
      </div>

      <h2 className="page-title mb-4">Add/Manage Winners</h2>

      <div className="page-content">
        <div className="sidebar">
          <ItemSidebarComponent
            items={campaigns.map((campaign) => ({
              id: campaign.id as number,
              image: campaign.campaignImage as string,
              title: campaign.campaignTitle,
              status: campaign.status === 0 ? "Active" : "Inactive",
              subtitle:
                `${campaign.closingDate}` || `${campaign.totalItemNumber}`,
            }))}
            selectionChange={onSelectionChanged}
            activeItemId={selectedItem?.id as number}
            search={onSearchTextChanged}
          ></ItemSidebarComponent>
        </div>

        <div className="winners">
          {selectedCampaign ? (
            <div className="mb-4" key={selectedCampaign.id}>
              <p className="fw-semibold">Main winner</p>

              <SelectWinnerComponent
                tickets={tickets}
                onAdd={onAdd}
                offerId={selectedCampaign.offerId!}
                winner={winners.find(
                  (w) => w.offerId === selectedCampaign.offerId
                )}
                delete={onDelete}
              ></SelectWinnerComponent>
            </div>
          ) : null}

          {selectedCampaign?.offers?.map((offer, i) => (
            <div key={i} className="mb-2">
              <p className="fw-semibold">
                {offer.offerTitle} winner {i + 1}
              </p>

              <SelectWinnerComponent
                tickets={tickets}
                onAdd={onAdd}
                offerId={offer.id!}
                delete={onDelete}
                winner={winners.find((w) => w.offerId === offer.id)}
              ></SelectWinnerComponent>
            </div>
          ))}
        </div>
      </div>
    </StyledLayout>
  );
};
