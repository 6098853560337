import axios from "axios";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (axios.isAxiosError(err)) {
      toast.error(
        err.response?.data.message || err.response?.data.errors || err?.message
      );
    } else {
      toast.error(err.message);
    }
    return Promise.reject({ ...err });
  }
);

export default instance;
