import { getCampaign } from "api/campaign";
import { getOrdersForCampaign } from "api/order";
import {
  StyledButton,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { ItemCardLarge } from "components/item-card-large";
import { colors } from "components/variables";
import { AuthContext } from "context-api/auth-provider";
import { DateTime } from "luxon";
import { Campaign } from "models/Campaign";
import { Order } from "models/Order";
import { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import styled from "styled-components";

const StyledPurchaseHistoryComponent = styled.div`
  .back-wrapper {
    width: 240px;
    margin-right: auto;

    button {
      justify-content: left;
    }
  }
`;

const StyledPurchaseHistoryTableComponent = styled.table`
  width: 100%;
`;

export const PurchaseHistory = () => {
  const campaignId = useLoaderData() as string;

  const [orders, setOrders] = useState<Order[]>([]);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const { token } = useContext(AuthContext);

  const stat = [
    { title: "Total items", value: campaign?.totalItemNumber || 0 },
    { title: "Items sold", value: campaign?.soldItemNumber || 0 },
    { title: "Number of offers", value: campaign?.numberOfOffers },
    {
      title: "Published date",
      value: campaign?.publishDate
        ? DateTime.fromISO(campaign?.publishDate).toFormat("dd/LL/yyyy")
        : "",
    },
    {
      title: "Closing date",
      value: campaign?.closingDate
        ? DateTime.fromISO(campaign.closingDate).toFormat("dd/LL/yyyy")
        : "",
    },
  ];

  const item = {
    id: Number(campaignId),
    image: `${campaign?.campaignImage}`,
    status: campaign?.status === 1 ? "active" : "inactive",
    subtitle: campaign?.closingDate
      ? DateTime.fromISO(campaign?.closingDate).toFormat("dd/LL/yyyy")
      : "",
    title: campaign?.campaignTitle ?? "",
  };

  useEffect(() => {
    getOrdersForCampaign(campaignId, token).then((res) => {
      setOrders(res.data);
    });
  }, []);

  useEffect(() => {
    getCampaign(campaignId, token).then((res) => {
      setCampaign(res.data);
    });
  }, []);

  return (
    <StyledPurchaseHistoryComponent>
      <div className="back-wrapper mb-4">
        <StyledLink to="/sales">
          <StyledButton>⬅️ Back</StyledButton>
        </StyledLink>
      </div>

      <ItemCardLarge item={{ ...item, stats: stat }} isActive={true} />

      <StyledPurchaseHistoryTableComponent className="history-table mt-3">
        <thead>
          <tr>
            <th>User</th>
            {/* <th>Shipping address</th> */}
            <th>Number of items</th>
            <th>Sub total</th>
            <th>VAT</th>
            <th>Total</th>
            <th>Delivery status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, i) => (
            <tr key={i}>
              <td>{order.userName}</td>
              {/* <td>WTC West Tower, Level 35, 00100</td> */}
              <td>{order.numberOfItems}</td>
              <td>{order.subTotal}</td>
              <td>{order.vat}</td>
              <td>{order.total}</td>
              <td>Pending</td>
              <td>
                <StyledButton
                  backgroundColor={colors.mediumGreen}
                  borderRadius="10px"
                  color={colors.white}
                >
                  Delivered
                </StyledButton>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledPurchaseHistoryTableComponent>
    </StyledPurchaseHistoryComponent>
  );
};
