import instance from "axiosClient";
import { Offer } from "models/Offer";

const offerUrl = "offer";

export const getOffers = (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Offer[]>(offerUrl, config);
};

export const addOffer = (offer: FormData, token: string) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.post(offerUrl, offer, config);
};

export const deleteOffer = (id: number, token: string) => {
  const url = `${offerUrl}/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.delete(url, config);
};

export const updateOffer = (id: number, offer: Offer, token: string) => {
  const url = `${offerUrl}/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return instance.put(url, offer, config);
};

export const searchOffers = (text: string, token: string) => {
  const url = `${offerUrl}/search/${text}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Offer[]>(url, config);
};
