import { Item } from "models/Item";
import { Product } from "models/Product";
import styled from "styled-components";
import { FullWidthImage } from "./common-styled-components/styled-components";
import { colors, styles } from "./variables";

const StyledItemCardWrapper = styled.div<{ isActive?: boolean }>`
  width: 100%;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  background-color: ${(props) =>
    props.isActive ? colors.lightPurple : colors.white};
  box-shadow: ${styles.boxShadow};
  border-radius: 10px;
  color: ${(props) => (props.isActive ? colors.white : colors.black)};

  .image-wrapper {
    flex: 1 1 20%;
    border-radius: 10px;
    overflow: hidden;
  }

  .details-wrapper {
    flex: 1 1 40%;
  }
`;

export const ItemCardComponent = (props: {
  item: Item;
  isActive?: boolean;
}) => {
  return (
    <StyledItemCardWrapper isActive={props.isActive}>
      <div className="image-wrapper">
        <FullWidthImage
          src={`${process.env.REACT_APP_API_URL}/image/${props.item.image}`}
        ></FullWidthImage>
      </div>
      <div className="details-wrapper pt-2">
        <h3>{props.item.title}</h3>
        <h5>{props.item.subtitle}</h5>
        <p>{props.item.status}</p>
      </div>
    </StyledItemCardWrapper>
  );
};
