import instance from "axiosClient";
import { Order } from "models/Order";
import { Ticket } from "models/Ticket";

const orderUrl = "order";

export const getOrdersForCampaign = (campaignId: string, token: string) => {
  const url = `${orderUrl}/campaign/${campaignId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Order[]>(url, config);
};

export const getTicketsForOrder = (campaignId: string, token: string) => {
  const url = `${orderUrl}/tickets/${campaignId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Ticket[]>(url, config);
};
