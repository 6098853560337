import instance from "axiosClient";
import { Campaign } from "models/Campaign";
import { Draw } from "models/Draw";

const baseUrl = "campaign";

export const getCampaigns = (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Campaign[]>(baseUrl, config);
};

export const addCampaign = (form: FormData, token: string) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.post(baseUrl, form, config);
};

export const updateCampaign = (
  id: number,
  payload: Campaign,
  token: string
) => {
  const url = `${baseUrl}/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.put(url, payload, config);
};

export const deleteCampaign = (id: number, token: string) => {
  const url = `${baseUrl}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.delete(url, config);
};

export const searchCampaigns = (text: string, token: string) => {
  const url = `${baseUrl}/search/${text}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Campaign[]>(url, config);
};

export const getCampaign = (id: string, token: string) => {
  const url = `${baseUrl}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Campaign>(url, config);
};

export const getDraw = (campaignId: number, token: string) => {
  const url = `${baseUrl}/${campaignId}/draw`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.get<Draw>(url, config);
};

export const updateDraw = (drawId: number, token: string, payload: Draw) => {
  const url = `${baseUrl}/draw/${drawId}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return instance.put(url, payload, config);
};
