import instance from "axiosClient";
import { UserRegistration } from "models/User";

const authUrl = "auth";

export const login = (login: UserRegistration) => {
  const url = `${authUrl}/login`;

  return instance.post<{ token: string }>(url, login);
};
