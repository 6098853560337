import { getCampaigns, getDraw, updateDraw } from "api/campaign";
import axios from "axios";
import {
  AppSpinner,
  StyledButton,
  StyledFormControl,
  StyledLink,
} from "components/common-styled-components/styled-components";
import { ItemSidebarComponent } from "components/items-sidebar";
import { DefaultAddManageWrapper } from "components/layout";
import { colors } from "components/variables";
import { AuthContext } from "context-api/auth-provider";
import { DateTime } from "luxon";
import { Campaign } from "models/Campaign";
import { Draw } from "models/Draw";
import { Item } from "models/Item";
import { useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";

const StyledLayout = styled(DefaultAddManageWrapper)`
  .page-content {
    gap: 3rem;
    overflow-y: auto;
  }
`;

export const ManageDrawComponent = (props) => {
  const { register, reset, setValue, handleSubmit } = useForm<Draw>();
  const { token } = useContext(AuthContext);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const drawId = useRef<number | null>(null);

  useEffect(() => {
    setIsLoading(true);

    getCampaigns(token)
      .then((res) => {
        setIsLoading(false);
        setCampaigns(res.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, []);

  const onSelectionChanged = (item: Item) => {
    setSelectedItem(item);

    getDraw(item.id, token).then((res) => {
      const draw = res.data;
      const drawDate =
        draw.drawDate && DateTime.fromISO(draw.drawDate).toFormat("yyyy-MM-dd");

      drawId.current = draw.id;

      setValue("drawDate", drawDate);
      setValue("status", draw.status);
    });
  };

  const onSearchTextChanged = async (text: string) => {
    setIsLoading(true);
    setSearchText(text);
    setSelectedItem(null);

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/campaign/search/${text}`;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get<Campaign[]>(url, config);
      setIsLoading(false);
      if (response.data.length === 0) {
        toast.error(`No products found for '${text}'`);
      }
      setCampaigns(response.data);
    } catch (err: any) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        toast.error(err.response?.data.error);
      } else {
        toast.error(err.message);
      }
    }
  };

  const onUpdate = async (payload: Draw) => {
    await updateDraw(drawId.current!, token, payload);
  };

  return (
    <StyledLayout>
      <AppSpinner show={isLoading}></AppSpinner>
      <div className="back-wrapper mb-4">
        <StyledLink to="/">
          <StyledButton>⬅️ Back</StyledButton>
        </StyledLink>
      </div>

      <h2 className="page-title mb-4">Add Draw</h2>

      <div className="page-content">
        <div className="sidebar">
          <ItemSidebarComponent
            items={campaigns.map((campaign) => ({
              id: campaign.id as number,
              image: campaign.campaignImage as string,
              title: campaign.campaignTitle,
              status: campaign.status === 0 ? "Active" : "Inactive",
              subtitle:
                `${campaign.closingDate}` || `${campaign.totalItemNumber}`,
            }))}
            selectionChange={onSelectionChanged}
            activeItemId={selectedItem?.id as number}
            search={onSearchTextChanged}
          ></ItemSidebarComponent>
        </div>

        <div className="product-info">
          <Form onSubmit={handleSubmit(onUpdate)}>
            <fieldset disabled={!selectedItem}>
              {/* <Form.Group className="mb-3" controlId="name">
                <Form.Label>Title</Form.Label>
                <StyledFormControl
                  type="text"
                  placeholder="Name"
                  {...register("title")}
                />
              </Form.Group> */}

              <div className="date-section">
                <Form.Group className="mb-3" controlId="pubDate">
                  <Form.Label>Published date</Form.Label>
                  <StyledFormControl
                    type="date"
                    placeholder=""
                    {...register("drawDate")}
                  />
                </Form.Group>
              </div>

              <Form.Group className="mb-3" controlId="status">
                <Form.Label>Status</Form.Label>
                <Form.Select {...register("status")}>
                  <option value=""></option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </Form.Select>
              </Form.Group>

              <div className="add-container">
                <StyledButton
                  backgroundColor={colors.colorMain}
                  color={colors.white}
                  type="submit"
                >
                  Update
                </StyledButton>
              </div>
            </fieldset>
          </Form>
        </div>
      </div>
    </StyledLayout>
  );
};
